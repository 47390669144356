window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.onboarding = window.locales.am.onboarding || {};window.locales.am.onboarding.onboarding = window.locales.am.onboarding.onboarding || {};window.locales.am.onboarding.onboarding = {...window.locales.am.onboarding.onboarding, ...{
    "question_1": "እድገት ለማግኘት<br> ወይስ የስራ ቅየራ ለማድረግ ፍላጎት አለዎት?",           
    "question_2": "የእርስዎን የቡድን ግንባታ እና የአመራር ክህሎቶች ለማሳደግ ይፈልጋሉ?",           
    "question_3": "የበለጠ <br> ለገበያ የሚቀርብ እና ከፍተኛ ደመወዝ የሚከፈለው ለመሆን ይፈልጋሉ?",           
    "question_4": "የራስዎን ቢዝነስ ለመጀመር መሳሪያዎችን እና መተማመንን ለማግኘት ይፈልጋሉ?"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.onboarding = window.locales.ar.onboarding || {};window.locales.ar.onboarding.onboarding = window.locales.ar.onboarding.onboarding || {};window.locales.ar.onboarding.onboarding = {...window.locales.ar.onboarding.onboarding, ...{
    "question_1": "هل أنت مهتم بالحصول على ترقية <br> أو أخذ خطوة للأمام في حياتك المهنية؟",           
    "question_2": "هل تريد تطوير مهارات بناء الفريق والقيادة الخاصة بك؟",           
    "question_3": "هل تسعى لأن تصبح مطلوباً <br> بشكل أكبر وأن تحصل على راتب أعلى؟",           
    "question_4": "هل تريد أن تحصل على الأدوات اللازمة واكتساب الثقة الكافية لبدء عملك الخاص بك؟"           
}
};window.locales.en = window.locales.en || {};window.locales.en.onboarding = window.locales.en.onboarding || {};window.locales.en.onboarding.onboarding = window.locales.en.onboarding.onboarding || {};window.locales.en.onboarding.onboarding = {...window.locales.en.onboarding.onboarding, ...{
    "question_1": "Are you interested in getting promoted <br> or making a career move?",
    "question_2": "Do you want to develop your team-building and leadership skills?",
    "question_3": "Are you looking to become more <br> marketable and earn a higher salary?",
    "question_4": "Do you want to gain the tools and confidence to start your own business?"
}
};window.locales.es = window.locales.es || {};window.locales.es.onboarding = window.locales.es.onboarding || {};window.locales.es.onboarding.onboarding = window.locales.es.onboarding.onboarding || {};window.locales.es.onboarding.onboarding = {...window.locales.es.onboarding.onboarding, ...{
    "question_1": "¿Le interesa ser ascendido <br> o hacer un cambio de profesión?",           
    "question_2": "¿Desea desarrollar sus habilidades de trabajo en equipo y liderazgo?",           
    "question_3": "¿Está buscando ser más<br> comercial y ganar un mejor sueldo?",           
    "question_4": "¿Quiere adquirir las herramientas y la confianza para iniciar su propio negocio?"           
}
};window.locales.it = window.locales.it || {};window.locales.it.onboarding = window.locales.it.onboarding || {};window.locales.it.onboarding.onboarding = window.locales.it.onboarding.onboarding || {};window.locales.it.onboarding.onboarding = {...window.locales.it.onboarding.onboarding, ...{
    "question_1": "Vuoi essere promosso <br> o compiere uno scatto di carriera?",           
    "question_2": "Vuoi sviluppare le tue capacità di leadership e team-building?",           
    "question_3": "Vuoi diventare più <br> competitivo sul mercato e guadagnare una retribuzione più alta?",           
    "question_4": "Vuoi ottenere gli strumenti e la sicurezza per avviare un'impresa tutta tua?"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.onboarding = window.locales.zh.onboarding || {};window.locales.zh.onboarding.onboarding = window.locales.zh.onboarding.onboarding || {};window.locales.zh.onboarding.onboarding = {...window.locales.zh.onboarding.onboarding, ...{
    "question_1": "您是否有志于升职<br>或改变职业生涯？",           
    "question_2": "您是否希望培养自己的团队建设和领导技能？",           
    "question_3": "您是否期望提高自身<br>价值，增加工资收入？",           
    "question_4": "您是否希望获得创业所需的工具和信心？"           
}
};