window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.onboarding = window.locales.am.onboarding || {};window.locales.am.onboarding.onboarding_hybrid_start = window.locales.am.onboarding.onboarding_hybrid_start || {};window.locales.am.onboarding.onboarding_hybrid_start = {...window.locales.am.onboarding.onboarding_hybrid_start, ...{
    "skip": "ዝለል",           
    "most_selective": "ትምህርት<br/ >እድልን የሚያገኝበት ስፍራ",           
    "zero_dollars": "$0",           
    "fast_track": "ስራዎን በፍጥነት ይከታተሉ።",           
    "with_our_mba": "በእኛ MBA፣ Executive MBA እና የተገናኘ የስራ ትስስር፣ የስራ ተስፋዎች እና ገንዘብ የማግኘት ሃይል ሁለቱንም ያሻሽላሉ።",           
    "learn_fast": "በፍጥነት ይማሩ። የትም ቦታ። በየትኛዉም ጊዜ፡",           
    "busy_but_motivated": "በጣም ስራ በዝቶብዎታል፣ ነገር ግን ተነሳሽነት አለዎት። የእኛን የተንቀሳቃስሽ-መጀመሪያ የዲግሪ ፕሮግራሞች ይሞክሩ እና MBA ዎን በአመታት ሳይሆን በወራት ውስጥ ያግኙ።",           
    "land_dream_job": "የሚያልሙትን ስራ ያግኙ።",           
    "connect_alumni_employers": "በእኛ የስራ መረብ ውስጥ ከጓደኞች፣ ከቀድሞ ተማሪዎች እና ለመቅጠር ከሚፈልጉ አሰሪዎች ጋር ይገናኛሉ።",           
    "radically_affordable": "ስር ነቀል የሆነ ተመጣጣኝ ዋጋ",           
    "sponsored_tuition_model": "ከተለመደው MBAዎች ጋር ሲነጻጸር የእኛ ስፖንሰር የተደረገ የትምህርት ሞዴል ስር ነቀል በሆነ መልኩ የተማሪ መጪዎችን ቀንሷል፣ አንዳንዴም $0 ድረስ ይሆናል።",           
    "get_started": "አሁን ጀምር",           
    "already_have_an_account": "ቀድሞውኑ መለያ አለዎት? <a ng-click='gotoSignIn(false)'>ይግቡ</a>"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.onboarding = window.locales.ar.onboarding || {};window.locales.ar.onboarding.onboarding_hybrid_start = window.locales.ar.onboarding.onboarding_hybrid_start || {};window.locales.ar.onboarding.onboarding_hybrid_start = {...window.locales.ar.onboarding.onboarding_hybrid_start, ...{
    "skip": "تخطي",           
    "zero_dollars": "$0",           
    "fast_track": "ارسم طريق حياتك المهنية.",           
    "learn_fast": "تعلم سريعأً. في أى مكان. في أي وقت.",           
    "land_dream_job": "احصل على وظيفة أحلامك.",           
    "get_started": "لتبدأ",           
    "already_have_an_account": "هل لديك حساب بالفعل؟ <a ng-click=\"loadRoute('/onboarding/hybrid/login?previous=start')\">تسجيل الدخول</a>",           
    "connect_alumni_employers": "في الشبكة الوظيفية لدينا عليك التواصل مع أقرانهم، والخريجين وأرباب العمل المحتملين الذين ينوون التوظيف.",
    "busy_but_motivated": "أنت مشغول ، ولكن دوافع. جرب برامجنا من الدرجة الأولى للهاتف المحمول واكسب ماجستير في إدارة الأعمال في شهور وليس سنوات.",
    "most_selective": "حيث التعليم <br/ > يلتقي الفرصة",
    "with_our_mba": "مع ماجستير في إدارة الأعمال لدينا ، ماجستير في إدارة الأعمال التنفيذية والشبكة الوظيفية ذات الصلة ، سوف تقوم بتحسين كل من فرص العمل وكسب القوة.",
    "radically_affordable": "بأسعار معقولة بشكل جذري",
    "sponsored_tuition_model": "يقلل نموذج التعليم المدعوم لدينا من تكاليف الطلاب بشكل كبير مقابل ماجستير إدارة الأعمال التقليدية ، وأحيانًا تصل إلى 0 دولار."
}
};window.locales.en = window.locales.en || {};window.locales.en.onboarding = window.locales.en.onboarding || {};window.locales.en.onboarding.onboarding_hybrid_start = window.locales.en.onboarding.onboarding_hybrid_start || {};window.locales.en.onboarding.onboarding_hybrid_start = {...window.locales.en.onboarding.onboarding_hybrid_start, ...{
    "skip": "Skip",
    "most_selective": "Where Education<br/ >Meets Opportunity",
    "zero_dollars": "$0",
    "fast_track": "Fast track your career.",
    "with_our_mba": "With our MBA, Executive MBA and related career network, you'll improve both job prospects and earning power.",
    "learn_fast": "Learn fast. Anywhere. Anytime.",
    "busy_but_motivated": "You're busy, but motivated. Try our mobile-first degree programs and earn your MBA in months, not years.",
    "land_dream_job": "Land your dream job.",
    "connect_alumni_employers": "In our career network you'll connect with peers, alumni and potential employers looking to hire.",
    "radically_affordable": "Radically Affordable",
    "sponsored_tuition_model": "Our Sponsored Tuition model lowers student costs dramatically vs traditional MBAs, sometimes all the way to $0.",
    "get_started": "Get Started",
    "already_have_an_account": "Already have an account? <a ng-click=\"loadRoute('/onboarding/hybrid/login?previous=start')\">Login</a>"
}
};window.locales.es = window.locales.es || {};window.locales.es.onboarding = window.locales.es.onboarding || {};window.locales.es.onboarding.onboarding_hybrid_start = window.locales.es.onboarding.onboarding_hybrid_start || {};window.locales.es.onboarding.onboarding_hybrid_start = {...window.locales.es.onboarding.onboarding_hybrid_start, ...{
    "skip": "Omitir",           
    "zero_dollars": "$0.00",           
    "fast_track": "Acelere su carrera.",           
    "learn_fast": "Aprenda rápido. En cualquier lugar. En cualquier momento.",           
    "land_dream_job": "Consiga el trabajo de sus sueños.",           
    "get_started": "Empezar",           
    "already_have_an_account": "¿Ya tiene una cuenta? <a ng-click=\"loadRoute('/onboarding/hybrid/login?previous=start')\">Iniciar sesión</a>",           
    "connect_alumni_employers": "En nuestra red de carrera que va a conectar con sus compañeros, alumnos y potenciales empleadores que buscan contratar.",
    "busy_but_motivated": "Estás ocupado, pero motivado. Pruebe nuestros programas móviles de primer grado y obtenga su MBA en meses, no años.",
    "most_selective": "Donde la educación <br/ > Cumple con la oportunidad",
    "with_our_mba": "Con nuestro MBA, Executive MBA y la red profesional relacionada, mejorará tanto las perspectivas de empleo como el poder adquisitivo.",
    "radically_affordable": "Radicalmente asequible",
    "sponsored_tuition_model": "Nuestro modelo de matrícula patrocinada reduce drásticamente los costos de los estudiantes en comparación con los MBA tradicionales, a veces hasta $ 0."
}
};window.locales.it = window.locales.it || {};window.locales.it.onboarding = window.locales.it.onboarding || {};window.locales.it.onboarding.onboarding_hybrid_start = window.locales.it.onboarding.onboarding_hybrid_start || {};window.locales.it.onboarding.onboarding_hybrid_start = {...window.locales.it.onboarding.onboarding_hybrid_start, ...{
    "skip": "Salta",           
    "zero_dollars": "$0",           
    "fast_track": "Fai carriera velocemente.",           
    "learn_fast": "Apprendi in fretta. In qualunque luogo. A qualsiasi ora.",           
    "land_dream_job": "Ottieni il lavoro che sogni.",           
    "connect_alumni_employers": "Nella nostra rete professionale potrai metterti in contatto con colleghi, laureati e potenziali datori di lavoro in cerca di personale.",           
    "get_started": "Comincia",           
    "already_have_an_account": "Hai già un account? <a ng-click=\"loadRoute('/onboarding/hybrid/login?previous=start')\">Accedi</a>",           
    "busy_but_motivated": "Sei occupato, ma motivato. Prova i nostri programmi di laurea mobile-first e guadagna il tuo MBA in mesi, non anni.",
    "most_selective": "Dove l'istruzione <br/ > Soddisfa le opportunità",
    "with_our_mba": "Con il nostro MBA, Executive MBA e la relativa rete di carriera, migliorerai sia le prospettive di lavoro che il potere di guadagno.",
    "radically_affordable": "Radicalmente accessibile",
    "sponsored_tuition_model": "Il nostro modello di lezioni sponsorizzate riduce drasticamente i costi degli studenti rispetto agli MBA tradizionali, a volte fino a $ 0."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.onboarding = window.locales.zh.onboarding || {};window.locales.zh.onboarding.onboarding_hybrid_start = window.locales.zh.onboarding.onboarding_hybrid_start || {};window.locales.zh.onboarding.onboarding_hybrid_start = {...window.locales.zh.onboarding.onboarding_hybrid_start, ...{
    "skip": "跳过",           
    "zero_dollars": "$0.00 ",           
    "fast_track": "快速推进您的职业生涯。",           
    "learn_fast": "快速学习。无论何地。无论何时。",           
    "land_dream_job": "得到您理想的工作。",           
    "get_started": "开始",           
    "already_have_an_account": "已有帐户？<a ng-click=\"loadRoute('/onboarding/hybrid/login?previous=start')\">登录</a>",           
    "connect_alumni_employers": "在我们的求职网络中，你可以与同事、校友和潜在雇主进行联系。",           
    "busy_but_motivated": "你很忙，但充满动力。试试我们的“移动优先”学位课程，可在数月内获得工商管理硕士（MBA ）学位，无需耗费数年时间。",           
    "most_selective": "教育<br/ >与机遇",           
    "with_our_mba": "借助我们的工商管理硕士（MBA ）、高级工商管理硕士（EMBA ）课程和相关的求职网络，你可以改善工作前景和收入能力。",           
    "radically_affordable": "经济实惠",           
    "sponsored_tuition_model": "与传统的工商管理硕士（MBA ）课程相比，我们的“赞助学费”模式大大降低了学员的成本，有时甚至可免费享受。"           
}
};