window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.onboarding = window.locales.am.onboarding || {};window.locales.am.onboarding.onboarding_forgot_password = window.locales.am.onboarding.onboarding_forgot_password || {};window.locales.am.onboarding.onboarding_forgot_password = {...window.locales.am.onboarding.onboarding_forgot_password, ...{
    "return_to_login": "<span>ወደ</span><a ng-click='goToLogin()'>&nbsp;በመለያ ማስገባት ይመለሱ</a>"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.onboarding = window.locales.ar.onboarding || {};window.locales.ar.onboarding.onboarding_forgot_password = window.locales.ar.onboarding.onboarding_forgot_password || {};window.locales.ar.onboarding.onboarding_forgot_password = {...window.locales.ar.onboarding.onboarding_forgot_password, ...{
    "return_to_login": "<span>العودة إلى</span><a ng-click='goToLogin()'>&nbsp;تسجيل الدخول</a>"           
}
};window.locales.en = window.locales.en || {};window.locales.en.onboarding = window.locales.en.onboarding || {};window.locales.en.onboarding.onboarding_forgot_password = window.locales.en.onboarding.onboarding_forgot_password || {};window.locales.en.onboarding.onboarding_forgot_password = {...window.locales.en.onboarding.onboarding_forgot_password, ...{
    "return_to_login": "<span>Return to</span><a ng-click='goToLogin()'>&nbsp;Login</a>"
}
};window.locales.es = window.locales.es || {};window.locales.es.onboarding = window.locales.es.onboarding || {};window.locales.es.onboarding.onboarding_forgot_password = window.locales.es.onboarding.onboarding_forgot_password || {};window.locales.es.onboarding.onboarding_forgot_password = {...window.locales.es.onboarding.onboarding_forgot_password, ...{
    "return_to_login": "<span>Volver al</span> <a ng-click='goToLogin()'>&nbsp;inicio de sesión</a>"           
}
};window.locales.it = window.locales.it || {};window.locales.it.onboarding = window.locales.it.onboarding || {};window.locales.it.onboarding.onboarding_forgot_password = window.locales.it.onboarding.onboarding_forgot_password || {};window.locales.it.onboarding.onboarding_forgot_password = {...window.locales.it.onboarding.onboarding_forgot_password, ...{
    "return_to_login": "<span>Torna ad</span><a ng-click='goToLogin()'>&nbsp;Accesso</a>"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.onboarding = window.locales.zh.onboarding || {};window.locales.zh.onboarding.onboarding_forgot_password = window.locales.zh.onboarding.onboarding_forgot_password || {};window.locales.zh.onboarding.onboarding_forgot_password = {...window.locales.zh.onboarding.onboarding_forgot_password, ...{
    "return_to_login": "<span>返回</span><a ng-click='goToLogin()'>登录</a>"           
}
};