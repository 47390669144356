window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.onboarding = window.locales.am.onboarding || {};window.locales.am.onboarding.onboarding_dialog_content = window.locales.am.onboarding.onboarding_dialog_content || {};window.locales.am.onboarding.onboarding_dialog_content = {...window.locales.am.onboarding.onboarding_dialog_content, ...{
    "before_we_begin": "ከመጀመራችን በፊት...",           
    "like_to_ask_you": "የእርስዎን የ MBA ማመልከቻ ከመጀመራችን በፊት ስለግቦችዎ 4 ጥያቄዎችን ልንጠይቅዎ እንፈልጋለን።",           
    "lets_go": "እንቀጥል!"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.onboarding = window.locales.ar.onboarding || {};window.locales.ar.onboarding.onboarding_dialog_content = window.locales.ar.onboarding.onboarding_dialog_content || {};window.locales.ar.onboarding.onboarding_dialog_content = {...window.locales.ar.onboarding.onboarding_dialog_content, ...{
    "before_we_begin": "قبل أن نبدأ...",           
    "like_to_ask_you": "نود أن نسألك أربعة أسئلة حول أهدافك قبل أن نبدأ في تقديم طلبك للحصول على MBA الخاصة بك.",           
    "lets_go": "هيا بنا!"           
}
};window.locales.en = window.locales.en || {};window.locales.en.onboarding = window.locales.en.onboarding || {};window.locales.en.onboarding.onboarding_dialog_content = window.locales.en.onboarding.onboarding_dialog_content || {};window.locales.en.onboarding.onboarding_dialog_content = {...window.locales.en.onboarding.onboarding_dialog_content, ...{
    "before_we_begin": "Before we begin...",
    "like_to_ask_you": "We’d like to ask you 4 questions about your goals before we start your MBA application.",
    "lets_go": "Let's Go!"
}
};window.locales.es = window.locales.es || {};window.locales.es.onboarding = window.locales.es.onboarding || {};window.locales.es.onboarding.onboarding_dialog_content = window.locales.es.onboarding.onboarding_dialog_content || {};window.locales.es.onboarding.onboarding_dialog_content = {...window.locales.es.onboarding.onboarding_dialog_content, ...{
    "before_we_begin": "Antes de comenzar...",           
    "like_to_ask_you": "Nos gustaría hacerle 4 preguntas acerca de sus metas antes de comenzar su solicitud para el MBA.",           
    "lets_go": "¡Vamos!"           
}
};window.locales.it = window.locales.it || {};window.locales.it.onboarding = window.locales.it.onboarding || {};window.locales.it.onboarding.onboarding_dialog_content = window.locales.it.onboarding.onboarding_dialog_content || {};window.locales.it.onboarding.onboarding_dialog_content = {...window.locales.it.onboarding.onboarding_dialog_content, ...{
    "before_we_begin": "Prima di iniziare…",           
    "like_to_ask_you": "Vorremmo porti 4 domande sui tuoi obiettivi prima di avviare la tua candidatura al MBA.",           
    "lets_go": "Cominciamo!"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.onboarding = window.locales.zh.onboarding || {};window.locales.zh.onboarding.onboarding_dialog_content = window.locales.zh.onboarding.onboarding_dialog_content || {};window.locales.zh.onboarding.onboarding_dialog_content = {...window.locales.zh.onboarding.onboarding_dialog_content, ...{
    "before_we_begin": "在我们开始之前...",           
    "like_to_ask_you": "在开始您的 MBA 申请之前，我们想问您 4 个有关您的目标的问题。",           
    "lets_go": "开始！"           
}
};