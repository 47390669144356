window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.onboarding = window.locales.am.onboarding || {};window.locales.am.onboarding.onboarding_hybrid_questionary = window.locales.am.onboarding.onboarding_hybrid_questionary || {};window.locales.am.onboarding.onboarding_hybrid_questionary = {...window.locales.am.onboarding.onboarding_hybrid_questionary, ...{
    "question_1": "እድገት ለማግኘት ወይስ የስራ ቅየራ ለማድረግ ፍላጎት አለዎት?",           
    "question_2": "የእርስዎን የቡድን ግንባታ እና የአመራር ክህሎቶች ለማሳደግ ይፈልጋሉ?",           
    "question_3": "የበለጠ ለገበያ የሚቀርብ እና ከፍተኛ ደመወዝ የሚከፈለው ለመሆን ይፈልጋሉ?",           
    "question_4": "የራስዎን ቢዝነስ ለመጀመር መሳሪያዎችን እና መተማመንን ለማግኘት ይፈልጋሉ?",           
    "yes": "አዎ",           
    "no": "አይ",           
    "header_text": "<p>ሃይ እንዴት ነዎት፣</p><p>ስለግቦችዎ ትንሽ ይንገሩን።</p>"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.onboarding = window.locales.ar.onboarding || {};window.locales.ar.onboarding.onboarding_hybrid_questionary = window.locales.ar.onboarding.onboarding_hybrid_questionary || {};window.locales.ar.onboarding.onboarding_hybrid_questionary = {...window.locales.ar.onboarding.onboarding_hybrid_questionary, ...{
    "question_1": "هل أنت مهتم بالحصول على ترقية أو أخذ خطوة للأمام في حياتك المهنية؟",           
    "question_2": "هل تريد تطوير مهارات بناء الفريق والقيادة الخاصة بك؟",           
    "question_3": "هل تسعى لأن تصبح مطلوباً بشكل أكبر وأن تحصل على راتب أعلى؟",           
    "question_4": "هل تريد أن تحصل على الأدوات اللازمة واكتساب الثقة الكافية لبدء عملك الخاص بك؟",           
    "yes": "نعم",           
    "no": "لا",           
    "header_text": "<p>مرحباً، </p><p> حدِثنا قليلاً عن أهدافك.</p>"           
}
};window.locales.en = window.locales.en || {};window.locales.en.onboarding = window.locales.en.onboarding || {};window.locales.en.onboarding.onboarding_hybrid_questionary = window.locales.en.onboarding.onboarding_hybrid_questionary || {};window.locales.en.onboarding.onboarding_hybrid_questionary = {...window.locales.en.onboarding.onboarding_hybrid_questionary, ...{
    "question_1": "Are you interested in getting promoted or making a career move?",
    "question_2": "Do you want to develop your team-building and leadership skills?",
    "question_3": "Are you looking to become more marketable and earn a higher salary?",
    "question_4": "Do you want to gain the tools and confidence to start your own business?",
    "yes": "Yes",
    "no": "No",
    "header_text": "<p>Hi there,</p><p>Tell us a bit about your goals.</p>"
}
};window.locales.es = window.locales.es || {};window.locales.es.onboarding = window.locales.es.onboarding || {};window.locales.es.onboarding.onboarding_hybrid_questionary = window.locales.es.onboarding.onboarding_hybrid_questionary || {};window.locales.es.onboarding.onboarding_hybrid_questionary = {...window.locales.es.onboarding.onboarding_hybrid_questionary, ...{
    "question_1": "¿Le interesa ser ascendido o hacer un cambio de profesión?",           
    "question_2": "¿Desea desarrollar sus habilidades de trabajo en equipo y liderazgo?",           
    "question_3": "¿Está buscando ser más comercial y ganar un mejor sueldo?",           
    "question_4": "¿Quiere adquirir las herramientas y la confianza para iniciar su propio negocio?",           
    "yes": "Sí",           
    "no": "No",           
    "header_text": "<p>Hola,</p><p>Cuéntenos un poco acerca de sus metas.</p>"           
}
};window.locales.it = window.locales.it || {};window.locales.it.onboarding = window.locales.it.onboarding || {};window.locales.it.onboarding.onboarding_hybrid_questionary = window.locales.it.onboarding.onboarding_hybrid_questionary || {};window.locales.it.onboarding.onboarding_hybrid_questionary = {...window.locales.it.onboarding.onboarding_hybrid_questionary, ...{
    "question_1": "Vuoi essere promosso o compiere uno scatto di carriera?",           
    "question_2": "Vuoi sviluppare le tue capacità di leadership e team-building?",           
    "question_3": "Vuoi diventare più competitivo sul mercato e guadagnare una retribuzione più alta?",           
    "question_4": "Vuoi ottenere gli strumenti e la sicurezza per avviare un'impresa tutta tua?",           
    "yes": "Sì",           
    "no": "No",           
    "header_text": "<p>Ehilà,</p><p>dicci qualcosa sui tuoi obiettivi.</p>"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.onboarding = window.locales.zh.onboarding || {};window.locales.zh.onboarding.onboarding_hybrid_questionary = window.locales.zh.onboarding.onboarding_hybrid_questionary || {};window.locales.zh.onboarding.onboarding_hybrid_questionary = {...window.locales.zh.onboarding.onboarding_hybrid_questionary, ...{
    "question_1": "您是否有志于升职或改变职业生涯？",           
    "question_2": "您是否希望培养自己的团队建设和领导技能？",           
    "question_3": "您是否期望提高自身价值，增加工资收入？",           
    "question_4": "您是否希望获得创业所需的工具和信心？",           
    "yes": "是",           
    "no": "否",           
    "header_text": "<p>您好，</p><p>请跟我们谈谈您的目标。</p>"           
}
};