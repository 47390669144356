window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.nominations = window.locales.am.nominations || {};window.locales.am.nominations.nominations = window.locales.am.nominations.nominations || {};window.locales.am.nominations.nominations = {...window.locales.am.nominations.nominations, ...{
    "nominations_title": "እጩዎች",
    "class_nomination_officer_title": "የስኮላርሺፕ ምርጫ ፕሮግራም",
    "class_nomination_officer_description": "እያንዳንዱ ቡድን፣ ተማሪዎች እና የቀድሞ ተማሪዎች ከአመራር ስኮላርሺፕ ጋር ለ{brandName} መግቢያ ሁለት እኩዮችን መሾም ይችላሉ። ለተጨማሪ ዝርዝሮች ይጠብቁን!<br><br> እስከዚያው ድረስ <strong class=\"font-semibold\">የክፍል ሹመት ኦፊሰር</strong> ለመሆን እና በክፍል ጓደኞችዎ መካከል ያለውን ተነሳሽነት ለማስተዋወቅ ከታች ያለውን ቅጽ ይሙሉ። ትቀበላለህ፡-",
    "class_nomination_officer_list_item_one": "የአለም አቀፍ የኮንፈረንስ ክፍያ ማቋረጥ፣ ዋጋው እስከ $700 ድረስ",
    "class_nomination_officer_list_item_two": "ለአዳዲስ ባህሪያት እና ኮርሶች ቀደምት \"የውስጥ አዋቂ መዳረሻ\".",
    "class_nomination_officer_list_item_three": "የአካባቢያዊ በአካል {brandName} ክስተት ለማስተናገድ እስከ $500 ድረስ",
    "form_section_title": "የክላስ ኦፊሰር ለመሆን ያመልክቱ",
    "form_section_submitted_title": "ማመልከቻ ተቀብሏል!",
    "form_section_submitted_description": "ስላመለከቱ እናመሰግናለን፣ ያስገቡት አሁን በቡድናችን እየተገመገመ ነው።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.nominations = window.locales.ar.nominations || {};window.locales.ar.nominations.nominations = window.locales.ar.nominations.nominations || {};window.locales.ar.nominations.nominations = {...window.locales.ar.nominations.nominations, ...{
    "nominations_title": "الترشيحات",
    "class_nomination_officer_title": "برنامج الترشيح للمنح الدراسية",
    "class_nomination_officer_description": "يمكن لكل مجموعة وطلاب وخريجين ترشيح اثنين من أقرانهم للقبول في {brandName} بمنح دراسية للقيادة. ترقبوا المزيد من التفاصيل!<br><br> في هذه الأثناء، قم بملء النموذج أدناه لتصبح <strong class=\"font-semibold\">مسؤول ترشيح الفصل</strong> وتساعد في تعزيز المبادرة بين زملائك في الفصل. سوف تتلقى:",
    "class_nomination_officer_list_item_one": "إعفاء من رسوم المؤتمر العالمي بقيمة تصل إلى 700 دولار",
    "class_nomination_officer_list_item_two": "الوصول المطلع المبكر إلى الميزات والدورات الجديدة",
    "class_nomination_officer_list_item_three": "ما يصل إلى 500 دولار لاستضافة حدث {brandName} محلي شخصيًا",
    "form_section_title": "تقدم بطلب لتصبح ضابط صف",
    "form_section_submitted_title": "تم استلام الطلب!",
    "form_section_submitted_description": "شكرًا لك على تقديم طلبك، طلبك الآن قيد المراجعة من قبل فريقنا."
}
};window.locales.en = window.locales.en || {};window.locales.en.nominations = window.locales.en.nominations || {};window.locales.en.nominations.nominations = window.locales.en.nominations.nominations || {};window.locales.en.nominations.nominations = {...window.locales.en.nominations.nominations, ...{
    "nominations_title": "Nominations",
    "class_nomination_officer_title": "Scholarship Nomination Program",
    "class_nomination_officer_description": "Each cohort, students and alumni can nominate two peers for {brandName} admission with leadership scholarships. Stay tuned for more details!<br><br>In the meantime, fill in a form below to become a <strong class=\"font-semibold\">Class Nomination Officer</strong> and help promote the initiative among your classmates. You'll receive:",
    "class_nomination_officer_list_item_one": "A global conference fee waiver, up to $700 in value",
    "class_nomination_officer_list_item_two": "Early “Insider Access” to new features and courses",
    "class_nomination_officer_list_item_three": "Up to $500 to host a local in-person {brandName} event",
    "form_section_title": "Apply to become a Class Officer",
    "form_section_submitted_title": "Application Received!",
    "form_section_submitted_description": "Thank you for applying, your submission is now under review by our team."
}
};window.locales.es = window.locales.es || {};window.locales.es.nominations = window.locales.es.nominations || {};window.locales.es.nominations.nominations = window.locales.es.nominations.nominations || {};window.locales.es.nominations.nominations = {...window.locales.es.nominations.nominations, ...{
    "nominations_title": "Nominaciones",
    "class_nomination_officer_title": "Programa de nominación de becas",
    "class_nomination_officer_description": "Cada cohorte, estudiantes y exalumnos pueden nominar a dos pares para la admisión a {brandName} con becas de liderazgo. ¡Estén atentos para más detalles!<br><br> Mientras tanto, complete el formulario a continuación para convertirse en <strong class=\"font-semibold\">Oficial de nominaciones de clase</strong> y ayudar a promover la iniciativa entre sus compañeros de clase. Recibirás:",
    "class_nomination_officer_list_item_one": "Una exención de tarifas de conferencia global, con un valor de hasta $700",
    "class_nomination_officer_list_item_two": "“Acceso interno” temprano a nuevas funciones y cursos",
    "class_nomination_officer_list_item_three": "Hasta $500 para organizar un evento {brandName} local en persona",
    "form_section_title": "Solicite convertirse en un oficial de clase",
    "form_section_submitted_title": "¡Aplicación recibida!",
    "form_section_submitted_description": "Gracias por presentar tu solicitud. Nuestro equipo está revisando tu envío."
}
};window.locales.it = window.locales.it || {};window.locales.it.nominations = window.locales.it.nominations || {};window.locales.it.nominations.nominations = window.locales.it.nominations.nominations || {};window.locales.it.nominations.nominations = {...window.locales.it.nominations.nominations, ...{
    "nominations_title": "Nomine",
    "class_nomination_officer_title": "Programma di nomina delle borse di studio",
    "class_nomination_officer_description": "Ciascun gruppo, studenti ed ex studenti può nominare due colleghi per l'ammissione a {brandName} con borse di studio per la leadership. Restate sintonizzati per maggiori dettagli!<br><br> Nel frattempo compila il modulo sottostante per diventare <strong class=\"font-semibold\">Class Nomination Officer</strong> e contribuire a promuovere l'iniziativa tra i tuoi compagni di classe. Riceverai:",
    "class_nomination_officer_list_item_one": "Un'esenzione dalla quota globale della conferenza, fino a un valore massimo di $ 700",
    "class_nomination_officer_list_item_two": "\"Accesso privilegiato\" anticipato a nuove funzionalità e corsi",
    "class_nomination_officer_list_item_three": "Fino a $ 500 per ospitare un evento {brandName} locale di persona",
    "form_section_title": "Fai domanda per diventare un ufficiale di classe",
    "form_section_submitted_title": "Applicazione ricevuta!",
    "form_section_submitted_description": "Grazie per aver presentato domanda, la tua richiesta è ora in fase di revisione da parte del nostro team."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.nominations = window.locales.zh.nominations || {};window.locales.zh.nominations.nominations = window.locales.zh.nominations.nominations || {};window.locales.zh.nominations.nominations = {...window.locales.zh.nominations.nominations, ...{
    "nominations_title": "提名",
    "class_nomination_officer_title": "奖学金提名计划",
    "class_nomination_officer_description": "每个班级、学生和校友都可以提名两名同学获得{brandName}录取并获得领导力奖学金。请继续关注更多详情！<br><br>与此同时，请填写下面的表格，成为<strong class=\"font-semibold\">班级提名官</strong>，并帮助在同学中宣传这一举措。您将收到：",
    "class_nomination_officer_list_item_one": "全球会议费用减免，价值高达 700 美元",
    "class_nomination_officer_list_item_two": "尽早“内部访问”新功能和课程",
    "class_nomination_officer_list_item_three": "高达 500 美元可举办当地现场 {brandName} 活动",
    "form_section_title": "申请成为班级官员",
    "form_section_submitted_title": "申请已收到！",
    "form_section_submitted_description": "感谢您的申请，我们的团队正在审核您提交的内容。"
}
};