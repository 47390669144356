window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.onboarding = window.locales.am.onboarding || {};window.locales.am.onboarding.onboarding_login = window.locales.am.onboarding.onboarding_login || {};window.locales.am.onboarding.onboarding_login = {...window.locales.am.onboarding.onboarding_login, ...{
    "login": "ወደ <strong>{{brandName}}</strong> ይግቡ",           
    "do_not_have_an_account": "መለያ የለዎትም?",           
    "get_started": "አሁን ጀምር",           
    "icp_file_number": "የሻንጋይ አይሲፒ ቁጥር 2021026217-2",
    "security_filing_number": "የሻንጋይ የህዝብ ኔትወርክ ደህንነት ቁጥር 31010602006704"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.onboarding = window.locales.ar.onboarding || {};window.locales.ar.onboarding.onboarding_login = window.locales.ar.onboarding.onboarding_login || {};window.locales.ar.onboarding.onboarding_login = {...window.locales.ar.onboarding.onboarding_login, ...{
    "do_not_have_an_account": "أليس لديك حساب؟",           
    "get_started": "لتبدأ",           
    "login": "تسجيل الدخول إلى <strong>{{brandName}}</strong>",
    "icp_file_number": "شنغهاي ICP رقم 2021026217-2",
    "security_filing_number": "Shanghai Public Network Security No. 31010602006704"
}
};window.locales.en = window.locales.en || {};window.locales.en.onboarding = window.locales.en.onboarding || {};window.locales.en.onboarding.onboarding_login = window.locales.en.onboarding.onboarding_login || {};window.locales.en.onboarding.onboarding_login = {...window.locales.en.onboarding.onboarding_login, ...{
    "login": "Login to <strong>{{brandName}}</strong>",
    "do_not_have_an_account": "Don't have an account?",
    "get_started": "Get Started",
    "icp_file_number": "Shanghai ICP No. 2021026217-2",
    "security_filing_number": "Shanghai Public Network Security No. 31010602006704"
}
};window.locales.es = window.locales.es || {};window.locales.es.onboarding = window.locales.es.onboarding || {};window.locales.es.onboarding.onboarding_login = window.locales.es.onboarding.onboarding_login || {};window.locales.es.onboarding.onboarding_login = {...window.locales.es.onboarding.onboarding_login, ...{
    "do_not_have_an_account": "¿No tiene una cuenta?",           
    "get_started": "Empezar",           
    "login": "Inicie sesión en <strong>{{brandName}}</strong>",
    "icp_file_number": "Shanghái ICP No. 2021026217-2",
    "security_filing_number": "Seguridad de la red pública de Shanghai No. 31010602006704"
}
};window.locales.it = window.locales.it || {};window.locales.it.onboarding = window.locales.it.onboarding || {};window.locales.it.onboarding.onboarding_login = window.locales.it.onboarding.onboarding_login || {};window.locales.it.onboarding.onboarding_login = {...window.locales.it.onboarding.onboarding_login, ...{
    "do_not_have_an_account": "Non hai un account?",           
    "get_started": "Comincia",           
    "login": "Accedi a <strong>{{brandName}}</strong>",
    "icp_file_number": "Shanghai ICP n. 2021026217-2",
    "security_filing_number": "Sicurezza della rete pubblica di Shanghai n. 31010602006704"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.onboarding = window.locales.zh.onboarding || {};window.locales.zh.onboarding.onboarding_login = window.locales.zh.onboarding.onboarding_login || {};window.locales.zh.onboarding.onboarding_login = {...window.locales.zh.onboarding.onboarding_login, ...{
    "do_not_have_an_account": "没有帐户？",           
    "get_started": "注册",           
    "login": "登录<strong>{{brandName}}</strong>",           
    "icp_file_number": "沪ICP备2021026217号-2",           
    "security_filing_number": "沪公网安备31010602006704号"           
}
};