window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.onboarding = window.locales.am.onboarding || {};window.locales.am.onboarding.onboarding_hybrid_register = window.locales.am.onboarding.onboarding_hybrid_register || {};window.locales.am.onboarding.onboarding_hybrid_register = {...window.locales.am.onboarding.onboarding_hybrid_register, ...{
    "header_text": " <p>በጣም ጥሩ!</p><p>እስቲ የእርስዎን መለያ እናዘጋጅ።</p>",           
    "header_text_miya_miya": "<p>እንኳን ባደህና መጡ!</p><p>እስቲ የእርስዎን መለያ እናዘጋጅ።</p>",           
    "already_have_an_account": "መለያ ቀድሞውኑ አለዎት?&nbsp;<span class='bolded-text'>በመለያ ይግቡ</span>"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.onboarding = window.locales.ar.onboarding || {};window.locales.ar.onboarding.onboarding_hybrid_register = window.locales.ar.onboarding.onboarding_hybrid_register || {};window.locales.ar.onboarding.onboarding_hybrid_register = {...window.locales.ar.onboarding.onboarding_hybrid_register, ...{
    "header_text": "<p>رائع! </p><p> دعنا نقوم بتهيئة حسابك.</p>",           
    "header_text_miya_miya": "<p style=\";text-align:right;direction:rtl\"> أهلا بك! </p><p style=\";text-align:right;direction:rtl\"> دعنا ننشئ حسابك. </p>",
    "already_have_an_account": "هل لديك حساب؟ <span class='bolded-text'>تسجيل الدخول"
}
};window.locales.en = window.locales.en || {};window.locales.en.onboarding = window.locales.en.onboarding || {};window.locales.en.onboarding.onboarding_hybrid_register = window.locales.en.onboarding.onboarding_hybrid_register || {};window.locales.en.onboarding.onboarding_hybrid_register = {...window.locales.en.onboarding.onboarding_hybrid_register, ...{
    "header_text": " <p>Great!</p><p>Let's set up your account.</p>",
    "header_text_miya_miya": "<p>Welcome!</p><p>Let's set up your account.</p>",
    "already_have_an_account": "Already have an account?&nbsp;<span class='bolded-text'>Login</span>"
}
};window.locales.es = window.locales.es || {};window.locales.es.onboarding = window.locales.es.onboarding || {};window.locales.es.onboarding.onboarding_hybrid_register = window.locales.es.onboarding.onboarding_hybrid_register || {};window.locales.es.onboarding.onboarding_hybrid_register = {...window.locales.es.onboarding.onboarding_hybrid_register, ...{
    "header_text": " <p>¡Genial!</p><p>Vamos a configurar su cuenta.</p>",           
    "header_text_miya_miya": "<p> ¡Bienvenido! </p><p> Configuremos su cuenta. </p>",
    "already_have_an_account": "¿Ya tienes una cuenta? <span class='bolded-text'>Acceso"
}
};window.locales.it = window.locales.it || {};window.locales.it.onboarding = window.locales.it.onboarding || {};window.locales.it.onboarding.onboarding_hybrid_register = window.locales.it.onboarding.onboarding_hybrid_register || {};window.locales.it.onboarding.onboarding_hybrid_register = {...window.locales.it.onboarding.onboarding_hybrid_register, ...{
    "header_text": " <p>Eccellente!</p><p>Impostiamo il tuo account.</p>",           
    "header_text_miya_miya": "<p> Benvenuto! </p><p> Configura il tuo account. </p>",
    "already_have_an_account": "Hai già un account? <span class='bolded-text'>Login"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.onboarding = window.locales.zh.onboarding || {};window.locales.zh.onboarding.onboarding_hybrid_register = window.locales.zh.onboarding.onboarding_hybrid_register || {};window.locales.zh.onboarding.onboarding_hybrid_register = {...window.locales.zh.onboarding.onboarding_hybrid_register, ...{
    "header_text": " <p>太好了！</p><p>让我们来设置您的账户吧。</p>",           
    "header_text_miya_miya": "<p>欢迎！</p><p>让我们建立账号。</p>",           
    "already_have_an_account": "已有账号？&nbsp;<span class='bolded-text'>登录</span>"           
}
};