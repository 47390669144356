window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.onboarding = window.locales.am.onboarding || {};window.locales.am.onboarding.onboarding_hybrid_login = window.locales.am.onboarding.onboarding_hybrid_login || {};window.locales.am.onboarding.onboarding_hybrid_login = {...window.locales.am.onboarding.onboarding_hybrid_login, ...{
    "get_started": "መለያ የለዎትም?&nbsp;<span class='bolded-text'>ይጀምሩ</span>"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.onboarding = window.locales.ar.onboarding || {};window.locales.ar.onboarding.onboarding_hybrid_login = window.locales.ar.onboarding.onboarding_hybrid_login || {};window.locales.ar.onboarding.onboarding_hybrid_login = {...window.locales.ar.onboarding.onboarding_hybrid_login, ...{
    "get_started": "ليس لديك حساب؟ <span class='bolded-text'>البدء"
}
};window.locales.en = window.locales.en || {};window.locales.en.onboarding = window.locales.en.onboarding || {};window.locales.en.onboarding.onboarding_hybrid_login = window.locales.en.onboarding.onboarding_hybrid_login || {};window.locales.en.onboarding.onboarding_hybrid_login = {...window.locales.en.onboarding.onboarding_hybrid_login, ...{
    "get_started": "Don't have an account?&nbsp;<span class='bolded-text'>Get Started</span>"
}
};window.locales.es = window.locales.es || {};window.locales.es.onboarding = window.locales.es.onboarding || {};window.locales.es.onboarding.onboarding_hybrid_login = window.locales.es.onboarding.onboarding_hybrid_login || {};window.locales.es.onboarding.onboarding_hybrid_login = {...window.locales.es.onboarding.onboarding_hybrid_login, ...{
    "get_started": "¿No tienes una cuenta? <span class='bolded-text'>Empezar"
}
};window.locales.it = window.locales.it || {};window.locales.it.onboarding = window.locales.it.onboarding || {};window.locales.it.onboarding.onboarding_hybrid_login = window.locales.it.onboarding.onboarding_hybrid_login || {};window.locales.it.onboarding.onboarding_hybrid_login = {...window.locales.it.onboarding.onboarding_hybrid_login, ...{
    "get_started": "Non hai un account? <span class='bolded-text'>Iniziare"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.onboarding = window.locales.zh.onboarding || {};window.locales.zh.onboarding.onboarding_hybrid_login = window.locales.zh.onboarding.onboarding_hybrid_login || {};window.locales.zh.onboarding.onboarding_hybrid_login = {...window.locales.zh.onboarding.onboarding_hybrid_login, ...{
    "get_started": "还未建立账号？&nbsp;<span class='bolded-text'>开始建立</span>"           
}
};