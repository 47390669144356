window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.onboarding = window.locales.am.onboarding || {};window.locales.am.onboarding.onboarding_hybrid_forgot_password = window.locales.am.onboarding.onboarding_hybrid_forgot_password || {};window.locales.am.onboarding.onboarding_hybrid_forgot_password = {...window.locales.am.onboarding.onboarding_hybrid_forgot_password, ...{
    "email_sent": "ኢሜይል ተልኳል",           
    "check_email": "የይለፍ ቃልዎን ዳግም ለማስጀመር መመሪያዎችን ለማግኘት ኢሜይልዎን ይፈትሹ።",           
    "back_to_login": "በመለያ ወደማስገባት ይመለሱ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.onboarding = window.locales.ar.onboarding || {};window.locales.ar.onboarding.onboarding_hybrid_forgot_password = window.locales.ar.onboarding.onboarding_hybrid_forgot_password || {};window.locales.ar.onboarding.onboarding_hybrid_forgot_password = {...window.locales.ar.onboarding.onboarding_hybrid_forgot_password, ...{
    "email_sent": "تم إرسال رسالة البريد الإلكتروني",           
    "check_email": "تحقق من بريدك الالكتروني للحصول على تعليمات إعادة تعيين كلمة المرور الخاصة بك.",           
    "back_to_login": "العودة إلى تسجيل الدخول"           
}
};window.locales.en = window.locales.en || {};window.locales.en.onboarding = window.locales.en.onboarding || {};window.locales.en.onboarding.onboarding_hybrid_forgot_password = window.locales.en.onboarding.onboarding_hybrid_forgot_password || {};window.locales.en.onboarding.onboarding_hybrid_forgot_password = {...window.locales.en.onboarding.onboarding_hybrid_forgot_password, ...{
    "email_sent": "Email sent",
    "check_email": "Check your email for instructions to reset your password.",
    "back_to_login": "Go back to Login"
}
};window.locales.es = window.locales.es || {};window.locales.es.onboarding = window.locales.es.onboarding || {};window.locales.es.onboarding.onboarding_hybrid_forgot_password = window.locales.es.onboarding.onboarding_hybrid_forgot_password || {};window.locales.es.onboarding.onboarding_hybrid_forgot_password = {...window.locales.es.onboarding.onboarding_hybrid_forgot_password, ...{
    "email_sent": "Correo electrónico enviado",           
    "check_email": "Revise en su correo electrónico las instrucciones para restablecer su contraseña.",           
    "back_to_login": "Volver a inicio de sesión"           
}
};window.locales.it = window.locales.it || {};window.locales.it.onboarding = window.locales.it.onboarding || {};window.locales.it.onboarding.onboarding_hybrid_forgot_password = window.locales.it.onboarding.onboarding_hybrid_forgot_password || {};window.locales.it.onboarding.onboarding_hybrid_forgot_password = {...window.locales.it.onboarding.onboarding_hybrid_forgot_password, ...{
    "email_sent": "Email inviata",           
    "check_email": "Controlla la tua email e troverai le istruzioni per reimpostare la password.",           
    "back_to_login": "Torna all'Accesso"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.onboarding = window.locales.zh.onboarding || {};window.locales.zh.onboarding.onboarding_hybrid_forgot_password = window.locales.zh.onboarding.onboarding_hybrid_forgot_password || {};window.locales.zh.onboarding.onboarding_hybrid_forgot_password = {...window.locales.zh.onboarding.onboarding_hybrid_forgot_password, ...{
    "email_sent": "已发送电子邮件",           
    "check_email": "请检查您的电子邮件以了解重置密码的说明。",           
    "back_to_login": "返回登录"           
}
};